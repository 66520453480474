import request from '../utils/request'
import baseUrl from './baseUrl'

export const newsCateList = (data) => request({
  url: baseUrl + '/newsCate/selectForBack',
  method: 'POST',
  data
})
export const newsCateAdd = (data) => request({
  url: baseUrl + '/newsCate/add',
  method: 'POST',
  data
})

export const newsCateModify = (data) => request({
  url: baseUrl + '/newsCate/modify',
  method: 'POST',
  data
})
export const newsCateDel = (data) => request({
  url: baseUrl + '/newsCate/deleteById',
  method: 'POST',
  data
})
export const newsCateAll = (data) => request({
  url: baseUrl + '/newsCate/selectAll',
  method: 'POST',
  data
})
export const newsList = (data) => request({
  url: baseUrl + '/news/selectForBack',
  method: 'POST',
  data
})
export const newsAdd = (data) => request({
  url: baseUrl + '/news/add',
  method: 'POST',
  data
})
export const newsDetail = (data) => request({
  url: baseUrl + '/news/selectForId',
  method: 'POST',
  data
})
export const newsDel = (data) => request({
  url: baseUrl + '/news/deleteById',
  method: 'POST',
  data
})
