<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加新闻分类</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom: 20px" />
    <el-form ref="formData" :model="formData" label-width="120px">
      <el-form-item label="分类">
        <el-input v-model="formData.cateName" maxlength="10"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 20px 0 0"
          @click="sumitForm('formData')"
          >确定</el-button
        >
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 0"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { newsCateAdd } from "../../api/news";
export default {
  components: {},
  data() {
    return {
      formData: {
        cateId: "0",
        cateName: "",
      },
    };
  },
  created() {
    this.formData.cateId = this.$route.query.id;
    this.formData.cateName = this.$route.query.cateName;
    console.log(this.$route.query.cateId);
    console.log(this.formData);
  },

  methods: {
    async addForm() {
      let that = this;
      const { data } = await newsCateAdd(this.formData);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    sumitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.addForm();
        } else {
          this.addForm();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px !important;
}
.title {
  font-weight: bold;
  margin-left: -90px;
  span {
    height: 13px;
    width: 5px;
    background-color: #3082fd;
    display: inline-block;
    margin-right: 5px;
  }
}
</style>